<template>
  <div class="embauche">
    <Banner>
      <div class="banner-content">
        <MeepIconSocial class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.socials") }}</span>
      </div>
    </Banner>

    <div
      class="embauche-content page-layout"
      :class="{ 'embauche-content--lcm': isLCM }"
    >
      <LoadingCard v-if="isLoading" />

      <md-card v-else class="meep-form">
        <PageHeader
          :title="$t('socials.embauche.title')"
          :has-back="true"
          :has-search="false"
          :has-actions="false"
          @back="goBack"
        />
        <!-- le formulaire de création -->
        <md-card-content>
          <!-- Numéro de SIREN -->
          <div class="meep-form-column socials-form">
            <div class="meep-form-column-title">
              <p class="meep-form-column-title-number">1</p>
              <p class="meep-form-column-title-text">
                {{ $t("socials.embauche.section-1-title") }}
              </p>
            </div>

            <!-- Company Select -->
            <div class="meep-input">
              <md-field>
                <label>
                  {{ $t("socials.company") }}
                </label>

                <md-select v-model="form.companyId">
                  <md-option
                    v-for="(company, index) in companies"
                    :key="index"
                    :value="company.id"
                  >
                    {{ company.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <!-- Last Name -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("form.lastName") }}</label>
                <md-input v-model="$v.form.lastName.$model"> </md-input>
              </md-field>
              <template v-if="$v.form.lastName.$dirty">
                <span v-show="!$v.form.lastName.alphaSpace" class="meep-error">
                  {{ $t("meep-errors.alphaSpace") }}
                </span>
                <span v-show="!$v.form.lastName.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
              </template>
            </div>

            <!-- First Name -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("form.firstName") }}</label>
                <md-input v-model="$v.form.firstName.$model"> </md-input>
              </md-field>
              <template v-if="$v.form.firstName.$dirty">
                <span v-show="!$v.form.firstName.alphaSpace" class="meep-error">
                  {{ $t("meep-errors.alphaSpace") }}
                </span>
                <span v-show="!$v.form.firstName.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
              </template>
            </div>

            <Chooser
              v-model="form.gender"
              :value="form.gender"
              :items="[
                { label: 'Féminin', value: 'Féminin' },
                { label: 'Masculin', value: 'Masculin' },
              ]"
            />

            <!-- Social Security Number -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("form.socialSecurityNumber") }}*</label>
                <md-input v-model="$v.form.secu.$model" name="secu" type="secu">
                </md-input>
                <template v-if="$v.form.secu.$dirty">
                  <span v-show="!$v.form.secu.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                  <span v-show="!$v.form.secu.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Birth Date -->
            <div class="meep-input">
              <md-datepicker v-model="$v.form.birthDate.$model">
                <label>
                  {{ $t("form.birthday") }}
                </label>
              </md-datepicker>
              <template v-if="$v.form.birthDate.$dirty">
                <span v-show="!$v.form.birthDate.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
              </template>
            </div>

            <!-- Address -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("form.address") }}</label>
                <md-input v-model="$v.form.adresse.$model" />
              </md-field>

              <template v-if="$v.form.adresse.$dirty">
                <span v-show="!$v.form.adresse.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
              </template>
            </div>

            <!-- Department -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("form.department") }}</label>
                <md-input v-model="$v.form.department.$model" />
              </md-field>

              <template v-if="$v.form.department.$dirty">
                <span v-show="!$v.form.department.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
                <span v-show="!$v.form.department.numeric" class="meep-error">
                  {{ $t("meep-errors.number") }}
                </span>
              </template>
            </div>

            <!-- Ville -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("form.city") }}</label>
                <md-input v-model="$v.form.city.$model" name="city" type="text">
                </md-input>
                <template v-if="$v.form.city.$dirty">
                  <span v-show="!$v.form.city.required" class="meep-error">
                    {{ $t("meep-errors.alphaSpace") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Code postal -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("form.postalCode") }}</label>
                <md-input
                  v-model="$v.form.codepostal.$model"
                  name="codepostal"
                  type="text"
                >
                </md-input>
                <template v-if="$v.form.codepostal.$dirty">
                  <span v-show="!$v.form.codepostal.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                  <div v-if="!$v.form.codepostal.maxLength" class="meep-error">
                    {{
                      $t("meep-errors.max", {
                        max: $v.form.codepostal.$params.maxLength.max,
                      })
                    }}
                  </div>
                </template>
              </md-field>
            </div>

            <!-- Country -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("form.country") }}</label>
                <md-input
                  v-model="$v.form.country.$model"
                  name="country"
                  type="country"
                >
                </md-input>
                <template v-if="$v.form.country.$dirty">
                  <span v-show="!$v.form.country.required" class="meep-error">
                    {{ $t("meep-errors.alphaSpace") }}
                  </span>
                </template>
              </md-field>
            </div>
          </div>

          <div class="meep-form-column socials-form">
            <!-- Email -->
            <div class="meep-input">
              <md-field>
                <label>E-mail*</label>
                <md-input v-model="$v.form.email.$model" name="email" />
                <template v-if="$v.form.email.$dirty">
                  <span v-show="!$v.form.email.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                  <span v-show="!$v.form.email.email" class="meep-error">
                    {{ $t("meep-errors.email") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Telfixe -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("form.telFix") }}</label>
                <md-input
                  v-model="$v.form.telfixe.$model"
                  name="telfixe"
                  type="tel"
                >
                </md-input>
                <template v-if="$v.form.telfixe.$dirty">
                  <span v-show="!$v.form.telfixe.tel" class="meep-error">
                    {{ $t("meep-errors.tel") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Téléphone portable  -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("form.telMobile") }}</label>
                <md-input
                  v-model="$v.form.telportable.$model"
                  name="telportable"
                  type="tel"
                >
                </md-input>
                <template v-if="$v.form.telportable.$dirty">
                  <span v-show="!$v.form.telportable.tel" class="meep-error">
                    {{ $t("meep-errors.tel") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Hire Date -->
            <div class="meep-input">
              <md-datepicker v-model="$v.form.hireDate.$model">
                <label>
                  {{ $t("form.hireDate") }}
                </label>
              </md-datepicker>
            </div>

            <div class="meep-input">
              <md-field>
                <label>
                  {{ $t("form.contractType") }}
                </label>
                <md-select v-model="$v.form.contract.$model">
                  <md-option
                    v-for="contractType in contractTypes"
                    :key="contractType.id"
                    :value="contractType.value"
                  >
                    {{ contractType.text }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('salary') }">
                <label>{{ $t("form.salary") }}</label>
                <md-input
                  v-model="$v.form.salary.$model"
                  name="salary"
                  type="text"
                >
                </md-input>
                <template v-if="$v.form.salary.$dirty">
                  <span v-show="!$v.form.salary.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                </template>
              </md-field>
            </div>

            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('trialPeriod') }">
                <label>{{ $t("form.trialPeriod") }}</label>
                <md-input
                  v-model="$v.form.trial.$model"
                  name="trialPeriod"
                  type="text"
                >
                </md-input>
                <template v-if="$v.form.trial.$dirty">
                  <span v-show="!$v.form.trial.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                </template>
              </md-field>
            </div>

            <div class="meep-form-column-title">
              <p class="meep-form-column-title-number">2</p>
              <p class="meep-form-column-title-text">
                {{ $t("socials.embauche.section-2-title") }}
              </p>
            </div>

            <md-checkbox v-model="form.nonCreateFile">
              {{ $t("socials.embauche.non-create-file") }}
            </md-checkbox>

            <div class="md-layout md-alignment-center-right create-button">
              <md-button class="md-raised md-primary" @click.native="save">
                {{ $t("socials.button-text") }}
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import companiesModel from "../../model/companies";
import PageHeader from "@/components/PageHeader";
import Banner from "@/components/Banner";
import MeepIconSocial from "@/components/icons/MeepIconSocial.vue";
import LoadingCard from "@/components/LoadingCard";
import Chooser from "@/components/Chooser.vue";

import { motifContrat, SOCIALS } from "../../constants";
import { alphaSpace, tel } from "../../services/vuelidate";
import { required, numeric, email, maxLength } from "vuelidate/lib/validators";
import FilesModel from "@/model/files";

export default {
  name: "Embauche",

  components: {
    LoadingCard,
    Banner,
    MeepIconSocial,
    PageHeader,
    Chooser,
  },

  data() {
    return {
      groups: [],
      isLoading: false,

      contractTypes: [
        { text: "Contrat à durée indéterminée", value: "CDI" },
        { text: "Contrat à durée déterminée", value: "CDD" },
        { text: "Contrat de travail temporaire", value: "CTT" },
      ],

      form: {
        type: SOCIALS.EMBAUCHE,
        employee: "",
        lastName: "",
        firstName: "",
        secu: null,
        codepostal: "",
        city: "",
        department: null,
        telfixe: "",
        telportable: "",
        adresse: "",
        country: "",
        email: "",
        birthDate: null,
        hireDate: null,
        contract: "",
        gender: "Féminin",
        endDate: "",
        trial: null,
        companyId: null,
        salary: null,
        justificatif: null,
        nonCreateFile: false,
      },
      fileName: null,
      companies: [],
      employees: [],
      motifContrat,
    };
  },

  validations: {
    form: {
      firstName: {
        alphaSpace,
        required,
      },
      lastName: {
        alphaSpace,
        required,
      },
      email: { email, required },
      secu: { required, numeric },
      birthDate: { required },
      department: { numeric, required },
      city: { required },
      adresse: { required },
      country: { required },
      codepostal: { numeric, maxLength: maxLength(5) },
      hireDate: { required },
      contract: { required },
      telfixe: { tel },
      telportable: { tel },
      salary: { numeric },
      trial: { numeric },
    },
  },
  computed: {
    ...mapGetters(["isJEPA", "isJEP", "isLCM"]),
  },

  mounted() {
    this.loadData();
  },

  methods: {
    goBack() {
      window.history.back();
    },

    async loadData() {
      try {
        this.isLoading = true;
        this.companies = await companiesModel.getAll();
        this.isLoading = false;
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });

        this.isLoading = false;
      }
    },

    async save() {
      const result = !this.$v.$invalid;
      if (result) {
        const dataObject = { data: this.form };
        try {
          await FilesModel.uploadDsn(dataObject);

          this.$toasted.global.AppSucces({
            message: this.$t("socials.embauche.success"),
          });
          this.$router.push("/dashboard/sociale/");
        } catch (error) {
          this.$toasted.global.AppError({
            message: error.msg,
          });
        }
      } else {
        this.$v.$touch();
        this.$toasted.global.AppInfo({
          message: this.$t("error.required"),
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";
#app {
  .embauche {
    &-content {
      .page-header {
        margin: 0;
        .page-header__title {
          @include for-lg {
            margin-bottom: toRem(27);
          }
        }
        .page-header__tabs-search {
          margin-bottom: 0;
        }
      }
      .md-card {
        .md-card-content {
          .socials-form {
            .chooser {
              margin-top: toRem(7);
              @include for-lg {
                margin-top: toRem(10);
              }
              .v-radio .v-label {
                font-size: toRem(9);
                @include for-lg {
                  font-size: toRem(15);
                }
              }
            }
            .md-button {
              margin-right: 0;
              font-size: toRem(9);
              @include for-lg {
                font-size: toRem(14);
              }
            }
            .meep-input {
              .md-field {
                margin: toRem(7) 0 0 0;
                @include for-lg {
                  margin: toRem(10) 0 0 0;
                }
              }
            }
            .meep-form-column-title {
              margin: toRem(25) 0 toRem(6) 0;
              @include for-lg {
                margin: toRem(32) 0 toRem(11) 0;
              }
              &:first-child {
                margin: 0 0 toRem(12);
              }
              .meep-form-column-title-text {
                font-size: toRem(11);
                @include for-lg {
                  font-size: toRem(16);
                }
              }
            }
            .md-checkbox.md-theme-default {
              margin-bottom: toRem(6);
              margin-right: 0;
              @include for-lg {
                margin-bottom: toRem(16);
              }
            }
          }
        }
      }
    }

    .meep-form-column:nth-child(2) {
      display: flex;
      margin: toRem(34) 0 0 0;
      @include for-lg {
        margin: toRem(38) 0 0 0;
      }
      .create-button {
        margin-top: auto;
      }
      .md-ripple {
        font-size: toRem(9);
      }
      @include for-lg {
        margin: toRem(42) 0 0 0;
        .md-ripple {
          font-size: toRem(14);
        }
      }
    }
    // font-size for checkbox
    .md-theme-default
      .md-card-content
      .meep-form-column.socials-form
      .md-checkbox.md-theme-default
      .md-checkbox-label {
      font-size: toRem(11);
      margin: 0;
      height: fit-content;
      @include for-lg {
        font-size: toRem(17);
      }
    }
    .chooser {
      margin-top: 0;
    }
    .md-card.meep-form.md-theme-default {
      @include for-lg {
        padding: toRem(34) toRem(46) toRem(34) toRem(50);
      }
    }
  }
}
.app-gc {
  .meep-form-column:nth-child(2) {
    margin-top: 57px;
  }
}
</style>
